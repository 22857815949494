const pt = {
    index: {
        title : 'Estado do meu pedido',
        subtitle : 'Para sua segurança, agradecemos que preencha todos os campos de informação.',
        moreInfo : 'Para esclarecer qualquer questão estamos aqui para ajudar, clique',
        moreInfoUrl : 'https://conforama.exevi.com/soporte/pt/',
        moreInfoClick : 'aqui',
    },
    form: {
        orderOnline : 'Tenho um pedido loja online',
        orderOffline : 'Tenho um pedido loja física',
        help: 'Ajuda',
        store : 'Loja',
        orderNr : 'Nº do pedido',
        orderEx : 'Ex: PPVT08500633',
        date : 'Data',
        amount : 'Valor total',
        zip : 'Código postal da morada de faturação',
        zipEx : 'Ex: 2005-129',
        zipPattern: '[0-9]{4}-[0-9]{3}',
        zipTooltip: 'Para os pedidos com recolha em loja, introduza o código postal da loja de recolha que se encontra no seu talão de venda ou na confirmação do seu pedido Online',
        search : 'Seguir o meu pedido',
        captchaError: 'Pergunta de segurança obrigatória',
        statusSearching : 'Procurando...',
        statusNotFound : 'Não foi possível encontrar nenhum pedido com esta informação',
        statusError : 'Desculpe, no momento não podemos verificar o status do seu pedido',
    },
    response: {
        title : 'Dados do pedido',
        orderOnlineNr : 'Nº do pedido online',
        orderStoreNr : 'Nº do pedido',
        date: 'Data',
        amount : 'Valor total',
        pending : 'Valor pendente',
        channel : 'Canal de vendas',
        updated : 'Atualizado em',
        billingAddress : 'Endereço de cobrança',
        shippingAddress: 'Endereço de entrega',
        details : 'Detalhes do pedido',
        colProduct : 'Produto',
        colSku : 'Código',
        colQty : 'Quantidad',
        colPrice: 'Preço',
        colDateRequest : 'Data solicitada',
        colDateConfirmed : 'Data confirmada',
        colStatus : 'Estado',
        units : 'ud/s',
        searchAgain : 'Faça outra pesquisa',
    },
    footer: {
        faq : 'Perguntas Frequentes',
        terms : 'Termos e Condições',
        policy : 'Política de privacidade',
    },
    stores: {
        625 : 'ALBUFEIRA',
        631 : 'ALTA LISBOA',
        623 : 'AMADORA',
        621 : 'CASCAIS',
        634 : 'CORROIOS',
        635 : 'FUNCHAL',
        636 : 'GAIA',
        637 : 'MATOSINHOS',
        632 : 'MONTIJO',
        633 : 'OLHAO',
        638 : 'SETUBAL',
        639 : 'SINTRA',
    },
    status: {
        'Confirmed order' : 'Pedido confirmado',
        'Order available for pickup' : 'Pedido disponível para recolha',
        'Shipment in preparation' : 'Em preparaçao para envio',
        'Ongoing delivery' : 'Distribuiçao em curso',
        'Package delivered to the logistics operator' : 'Package delivered to the logistics operator',
        'Delivered' : 'Entregue',
        'Return made' : 'Devoluçao processada',
        'Expected pickup for the "date"' : 'Recolha prevista em fecha',
        'Goods collected' : 'Mercadoria recolhida',
        'Return completed' : 'Devoluçao completa',
        'Payment not received by the stipulated deadline' : 'Pagamento não recebido no prazo estipulado',
        'Pending payment' : 'Pendente de pagamento',
    }
  }
  
  export default pt